.track {
  margin: 0 0 24px 0;
  display: flex;
  padding: 8px;
  /* height: 16em; */
  height: 14.5em;
  min-width: 0;
  width: 100%;
  max-width: 1500px;
  box-sizing: border-box;
  border-radius: 8px;
  /* background-color: #f2f5fa; */
  /* background: #ffecd1; */
  background: white;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.track-left {
  height: 100%;
}

.track-left img {
  height: 100%;
  width: auto;
}

.track-right {
  min-width: 0;
  flex: 1;
}

.track-top {
  display: flex;
  flex: 1;
}

.track-title {
  font-size: 24px;
}

.play-pause {
  background: none;
  width: 4.5em;
  height: 4.5em;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
}

.play-pause-img {
  height: 50px;
  width: 50px;
}

.track-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.track-middle {
  position: relative;
  height: 7.5em;
  flex: 1;
}

#track-canvas-container {
  z-index: 1;
  height: 100%;
  position: relative;
}

.unplayed-wave-img {
  position: absolute;
  width: 100%;
  height: 100%;
  /* filter: invert(9%) sepia(8%) saturate(5540%) hue-rotate(161deg) brightness(97%) contrast(106%); */
}

.played-wave-img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.track-canvas-played-portion {
  position: absolute;
  width: 0;
  height: 7.5em;
  z-index: 2;
  overflow: hidden;
  border-right: 2px solid rgb(212, 117, 55);
  /* backdrop-filter: invert(37%) sepia(80%) saturate(956%) hue-rotate(353deg) brightness(90%) contrast(89%); */
  /* backdrop-filter: sepia(50%); */
}

.track-canvas-unplayed-portion {
  position: absolute;
  right: 0;
  width: 100%;
  height: 7.5em;
  z-index: 2;
  overflow: hidden;
}

.track-canvas {
  left: 0;
  position: absolute;
  width: 100%;
  height: 7.5em;
  opacity: 0;
  z-index: 4;
  cursor: pointer;
}

.track-meta-data {
  display: flex;
}

.tags {
  display: flex;
  padding: 16px 8px 8px;
  flex-wrap: wrap;
  /* width: 100%; */
  /* overflow: scroll; */
  height: 2em;
}

.tags > span, .track-meta-data > span {
  margin: 0 5px 0 0;
}

.tags > span {
  color: gray;
}

@media only screen and (max-width: 1400px) {
  .track {
    height: 16em;
  }
}

@media only screen and (max-width: 800px) {
  .track .tags {
    display: none;
  }
  .track {
    height: 14.5em;
  }
  
  .track .track-title {
    font-size: 16px;
  }

  .track .play-pause {
    margin-right: 3px;
  }

  .track .track-meta-data {
    margin-left: 10px;
  }

  .track .play-pause-img {
    height: 3em;
    width: 3em;
  }

  .track nav span {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  /* .track-meta-data {
    display: none;
  } */

  .track .track-left {
    display: none;
  }
}
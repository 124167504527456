.prodlib {
  /* background: #15616d; */
  background: rgba(24, 206, 252, 0.3);
  font-family: 'Work Sans', sans-serif;
  /* background: #15616d; */
}

nav {
  width: 100%;
  justify-content: space-between;
  height: 96px;
  padding: 0 2em;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  background-color: #001524;
  display: flex;
  align-items: center;
  z-index: 2;
}

.nav-left-container {
  display: flex;
  align-items: center;
}

.nav-left-container span {
  color: rgb(212, 117, 55);
  font-size: 24px;
}

nav > div > img {
  height: 64px;
  width: 64px;
  margin-right: 2em;
}

nav > span {
  color: rgb(212, 117, 55);
  font-size: 24px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.prodlib-main {
  margin-top: 96px;
  display: flex;
  justify-content: center;
}

.flex {
  display: flex;
}

.track-container {
  /* width: 800px; */
  /* width: 100%; */
  padding: 24px;
}
